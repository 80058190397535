<template>
  <div>
    <button 
      class='block lg:hidden lg:px-2 py-3 uppercase'
      @click='toggleShowFilters'>
      <adjustments-icon class='inline-block' /> Filters
    </button>
    <div 
      class='filters z-10 bg-white w-full border lg:border-none p-4 lg:p-0 shadow-md lg:shadow-none'
      :style='filterPosition'>
      <h2 class='text-2xl text-gray-700 uppercase font-semibold mb-6 lg:hidden'>
        Filters
      </h2>
      <single-filter
        v-for='filter in filterList'
        :key='`filter-${filter.name}`'
        :single-filter='filter'
        @update-filter-values='updateFilterValues' />
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import { AdjustmentsIcon } from '@vue-hero-icons/outline'
import SingleFilter from '@/components/SingleFilter.vue'

export default {
  name: 'SpeakerFilters',
  components: {
    AdjustmentsIcon,
    SingleFilter,
  },
  data () {
    return {
      showContentFilter: false,
    }
  },
  computed: {
    ...mapGetters('filters', [
      'showingContentsFilter'
    ]),
    filterList () {
      return this.showingContentsFilter('speaker').keywords
    },
    filterPosition () {
      return (this.showContentFilter) ? 'top:40vh' : 'top:100vh'
    }
  },
  methods: {
    ...mapActions('filters', [
      'getFilters',
    ]),
    toggleShowFilters () {
      this.showContentFilter = !this.showContentFilter
    },
    updateFilterValues (updatedFilter) {
       this.$emit('update-filter-values', updatedFilter)
    },
  },
  mounted () {
    this.getFilters()
  }
}
</script>

<style lang='scss' scoped>
.filters {
  @apply overflow-y-auto;
  transition: all 0.15s ease-in-out;
  position: fixed;
  left: 0;
  height: 60vh;
  top: 100vh;
}

 @media (min-width: 1024px) { 
  .filters {
    position: static;
    height: auto;
  }
 }
 </style>
